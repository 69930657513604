import React from 'react'
import { UnderConstruction } from '../../../components';

const PlasteringAndRendering = () => (
  <UnderConstruction />
  // <Layout headerBreakpoint={170}>
  //   <SEO title="Plastering And Rendering" />

  //   <Spotlight img={data.roller}/>

  //   <section>
  //     <h1>Plastering And Rendering</h1>
  //   </section>

  //   <section>
  //     <div>
  //       <div style={{overflow: 'hidden', margin: '-20px'}}>
  //         <Card image={data.glass}>
  //           <h3>Commercial Plastering</h3>
            
  //           <p></p>

  //           <Link to="/services/plastering-and-rendering/commercial-plastering" className="button primary">Find Out More</Link>
  //         </Card>

  //         <Card image={data.glass}>
  //           <h3>Flood Restoration</h3>
            
  //           <p></p>

  //           <Link to="/services/plastering-and-rendering/flood-restoration" className="button primary">Find Out More</Link>
  //         </Card>

  //         <Card image={data.glass}>
  //           <h3>Exterior Rendering</h3>
            
  //           <p></p>

  //           <Link to="/services/plastering-and-rendering/exterior-rendering" className="button primary">Find Out More</Link>
  //         </Card>

  //         <Card image={data.glass}>
  //           <h3>Residential Plastering</h3>
            
  //           <p></p>

  //           <Link to="/services/plastering-and-rendering/residential-plastering" className="button primary">Find Out More</Link>
  //         </Card>
  //       </div>
  //     </div>
  //   </section>
  // </Layout>
)

export default PlasteringAndRendering
